/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/jsx-no-target-blank */
import styles from '../styles/layout/_footer.module.scss';
// import Image from 'next/future/image'
import { useId } from 'react';
// import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useSelector } from 'react-redux';
const Footer = () => {
  const id = useId();
  const router = useRouter();
  const { footerLinkData } = useSelector((s) => s.globalSlice);

  return (
    <footer className={styles.page_footer} id={`${id}_footer`}>
      <div className={styles.footer_bg}>
        <img src="/assets/images/New_footer.webp" alt="" />
      </div>
      <div className="container">
        <div className={styles.topbrdr}>
          {router.pathname === '/contact' ? null : (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={0}>
                <Grid md={6} sm={6} xs={12}>
                  <div className={styles.footer_contact}>
                    <h3>Contact Us</h3>
                    <ul>
                      {/* <li className={styles.ftCall}>
                        <div className={styles.round_icon}>
                          <img src="/assets/images/icon-location.svg" alt="" />
                        </div>
                        <a
                          target="_blank"
                          href="https://goo.gl/maps/BfGEXGMmSiYVgsQv5"
                          className={styles.telno}
                        >
                          Consectetur adipiscing elit 1234 Fusce 0005
                        </a>
                      </li> */}
                      <li className={styles.ftMail}>
                        <div className={styles.round_icon}>
                          <img src="/assets/images/icon-mail.svg" alt="" />
                        </div>
                        <a
                          target="_blank"
                          href="mailto:info@militarymovesyou.com"
                          className={styles.mailid}
                        >
                          info@militarymovesyou.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </Grid>
                <Grid md={6} sm={6} xs={12}>
                  <div className={styles.footer_quickLinks}>
                    <h3>Quick Links</h3>
                    <Box sx={{ flexGrow: 1 }} className={styles.linksList}>
                      <Grid container spacing={2}>
                        <Grid md={6} xs={6}>
                          <ul>
                            <li>
                              <Link href="/">Home</Link>
                            </li>
                            <li>
                              <Link href="/about">About Us</Link>
                            </li>
                            {/* <li>
                              <Link href="/faqs">FAQs</Link>
                            </li> */}
                            <li>
                              <Link href="/contact">Contact Us</Link>
                            </li>
                          </ul>
                        </Grid>
                        <Grid md={6} xs={6}>
                          <ul>
                            {/* <li>
                              <Link href="/Abuse-policy">Abuse Policy</Link>
                            </li> */}
                            {/* <li>
                              <Link href="/Privacy-Policy">Privacy Policy</Link>
                            </li> */}
                            <li>
                              <Link href="/faqs">FAQs</Link>
                            </li>
                            <li>
                              <Link href="/terms-condition">
                                Terms and Condition
                              </Link>
                            </li>
                            <li>
                              <Link href="/affliation">Affilation</Link>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}

          <div className={styles.footer_bottom}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={0} className={styles.itemsCenters}>
                <Grid md={4} xs={12}>
                  <div className={styles.bottom_left}>
                    <span>Follow Us on:</span>
                    <ul style={{ cursor: 'pointer' }}>
                      <li>
                        <a
                          target="_blank"
                          href={footerLinkData?.socialLinks?.lnkdn}
                          >
                          <img src="/assets/images/icon-linkdin.svg" alt="" />
                        </a>
                      </li>
                      {/* <li>
                        <Link
                          target="_blank"
                          href={footerLinkData?.socialLinks?.twtr}
                        >
                          <img src="/assets/images/icon-tweet.svg" alt="" />
                        </Link>
                      </li> */}
                      <li>
                        <a
                          href={footerLinkData?.socialLinks?.fb}
                          target="_blank"
                        >
                          <img src="/assets/images/icon-facebook.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href={footerLinkData?.socialLinks?.insta}
                          target="_blank"
                        >
                          <img src="/assets/images/icon-insta.svg" alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Grid>
                <Grid md={4} xs={12}>
                  <div className={styles.bottom_middle}>
                    <img src="/assets/images/footer-logo.svg" alt="" />
                  </div>
                </Grid>
                <Grid md={4} xs={12}>
                  <div className={styles.bottom_right}>
                    <p>© 2022 Military Moves. All Rights Reserved.</p>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
